<template>
  <div class="header p-4" style="background-color: rgba(201, 209, 219, 0.2);">
    <div class="container d-flex justify-content-lg-between justify-content-center">
      <a @click="$router.push({ name: 'Home' })" class="logo btn">
        <img alt="Logo Mask" src="../assets/images/logo-mask.png">
      </a>
      <div class="nav d-none">
        <ul>
          <li>test1</li>
          <li>test2</li>
          <li>test3</li>
        </ul>
      </div>
      <div class="right-panel">
        <button class="d-none btn btn-primary">Login/Register</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
}
</script>