<template>
  <div class="col-12">
    <div class="row d-flex align-items-center page-transition">
      <div class="col-12 col-lg-6">
        <h1 class="fw-700 text-center text-lg-start">{{ data.settings.main_title }}</h1>
        <h5 class="col-12 col-lg-10 fw-500 text-muted my-4 text-center text-lg-start" style="line-height: 1.8rem;">{{ data.settings.title_desc }}</h5>
        <h5 class="col-12 col-lg-8 fw-500 my-4 text-center text-lg-start">Completely free, always.</h5>
        <div class="text-center text-lg-start">
          <router-link :to="{ name: 'Questions', params: { index: 0 } }" class="btn btn-primary fw-700 px-5 py-3 rounded-4 fs-5 ">Start Estimate</router-link>
        </div>
        <h5 class="col-12 col-lg-8 fw-500 mt-5 text-muted text-center text-lg-start" v-bind:class="(estimate_count < 500) ? 'd-none' : ''">{{ estimate_count.toLocaleString() }} estimates to date</h5>
      </div>
      <div class="col-12 col-lg-6 mt-5 mt-lg-0">
        <img class="mw-100" alt="Get Started on Reev Estimation" :src="intro_image" rel="preload" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  data() {
    return {
      data: this.$store.state.json_data,
      estimate_count: 0,
      intro_image: require('../assets/images/get-started.png')
    }
  },
  methods: {
    async getNumber() {
      try {
        let response = await fetch(this.$endpointURL + '/api/index.php');
        this.estimate_count = await response.json();
        this.estimate_count = this.estimate_count.data.count
      } catch (error) {
        console.log(error);
      }
    }
  },
  beforeMount() {
    this.getNumber()
  }
}
</script>
