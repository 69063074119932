import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuex from 'vuex'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/style.css';
import router from './router';
import json from './data.json';


Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.prototype.$endpointURL = 'https://estimator.reev.dev'

const store = new Vuex.Store({
  state: {
    json_data: json,
    initial_price: json.settings.base_price,
    initial_time: json.settings.base_time,
    questions_answers: ( sessionStorage.getItem('answers_array') ? JSON.parse(sessionStorage.getItem('answers_array')) : [] ),
    post_sent: ( sessionStorage.getItem('post_sent') ? true : false ),
    recaptcha_token: null
  },
  mutations: {
    saveState(state, payload) {
      state.questions_answers[payload[0].question_index] = payload[0];
      sessionStorage.setItem('answers_array', JSON.stringify(state.questions_answers));
    },
    clearState(state) {
      state.questions_answers = []
      state.post_sent = false
      sessionStorage.clear()
    }
  }
})

new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')


Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});