<template>
  <div id="app">
    <Header></Header>
    <div class="container my-5">
      <transition mode="out-in" name="fade" appear>
        <router-view :key="$route.fullPath" />
      </transition>
    </div>
  </div>
</template>

<style>
.page-transition {
  transition: opacity .5s;
}

.fade-enter .page-transition,
.fade-leave-to .page-transition {
  opacity: 0;
}
</style>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

</style>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Reev Estimator';
      }
    },
  },
  components: {
    Header
  },
  methods: {
    // getGlobalData: function() {
    //   try {
    //     let response = fetch('data.json');
    //     this.$main_data = JSON.parse(response);
    //     this.$store.state.initial_price = this.$main_data.settings.base_price;
    //     this.$store.state.initial_time = this.$main_data.settings.base_time;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  },
  beforeMount(){
    // this.getGlobalData()
  },
}
</script>