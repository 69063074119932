import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/question/:index',
    name: 'Questions',
    component: () => import('../views/Questions'),
  },
  {
    path: '/summary',
    name: 'Summary',
    component: () => import('../views/Summary'),
    meta: { title: 'Reev Estimator | Summary' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
